import React, {useCallback, useState} from 'react';
import PopoverBtn from '../../../svg/PopoverBtn';
import {Popover} from 'antd';
import popoverCss from './SectionPopover.module.css';
import CreatePositionModal from '../CreatePositionModal/CreatePositionModal';

export default function SectionPopover(props: any) {

    const [modalVisible, setIsModalVisible] = useState(false);

    const contentList = () => {
        return (
            <ul className={popoverCss.popoverActionList}>
                <li onClick={tableAddBtnCallback}>Добавить позицию</li>
                <li onClick={tableDeleteBtnCallback}>Удалить</li>
            </ul>
        );
    }

    const [visible, setVisible] = useState(false);

    const tableAddBtnCallback = () => {
        setIsModalVisible(!modalVisible);
        setVisible(false);
    }

    const tableDeleteBtnCallback = () => {
        props.handleDelete(props.categoryId);
        setVisible(false);
    }


    const handleVisibleChange = (visible: any) => {
        setVisible(visible);
    };

    const handleModalState = useCallback(
        e => {
            setIsModalVisible(e);
        }, []
    );

    return (
        <>
            <Popover
                content={contentList}
                trigger="click"
                visible={visible}
                placement="bottom"
                onVisibleChange={handleVisibleChange}
            >
                <div onClick={handleVisibleChange}><PopoverBtn/></div>
            </Popover>

            {modalVisible &&
            <CreatePositionModal
                visible={modalVisible}
                handleChange={handleModalState}
                materialId={props.materialId}
                categoryId={props.categoryId}/>}
        </>
    );
}
