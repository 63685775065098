import styles from './NomTableItemInfoRow.module.css';
import React, {useState} from 'react';
import IconAddPlus from '../../svg/IconAddPlus';


export default function NomTableItemInfoRowBlank(props: {
    materialId: number
    categoryId: number,
    itemId: number,
    handleAdd: any
}) {

    const [itemValue, setItemValue] = useState({
        vendorCode: null,
        title: '',
        count: null,
        weight: null,
        unit: '',
        price: null,
        markup: null,
        logistic: null,
        vat: null,
        firstLayer: null,
        secondLayer: null,
        thirdLayer: null
    });

    const onValueChange = (e: any) => {
        let {name, value} = e.target;

        setItemValue(prevState => ({
            ...prevState,
            [name]: transformValue(value, name)
        }));
    }

    const addItem = () => {
        props.handleAdd(itemValue);
    }

    const transformValue = (val: number | string, name: string) => {

        if (name === 'unit') {
            return val;
        }

        if (name === 'title') {
            return val;
        }

        return Number(val);
    }

    return (
        <tr className={`nom-row ${styles.nomItem} ${styles.nomItemBlank}`}>
            <td/>
            <td><input placeholder="Указать" type="text" name="vendorCode" onChange={onValueChange}/></td>
            <td><input placeholder="Указать" type="text" name="title" onChange={onValueChange}/></td>

            <td><input placeholder="Указать" type="number" name="price" onChange={onValueChange}/></td>
            <td>-</td>

            <td><input placeholder="Указать" type="number" name="vat" onChange={onValueChange}/></td>
            <td>-</td>

            <td><input placeholder="Указать" type="number" name="logistics" onChange={onValueChange}/></td>
            <td>-</td>
            <td>-</td>

            <td><input placeholder="Указать" type="number" name="markup" onChange={onValueChange}/></td>
            <td>-</td>

            <td><input placeholder="Указать" type="number" name="count" onChange={onValueChange}/></td>
            <td><input placeholder="Указать" type="number" name="weight" onChange={onValueChange}/></td>
            <td><input placeholder="Указать" type="text" name="unit" onChange={onValueChange}/></td>

            <td><input placeholder="Указать" type="number" name="firstLayer" onChange={onValueChange}/></td>
            <td><input placeholder="Указать" type="number" name="secondLayer" onChange={onValueChange}/></td>
            <td><input placeholder="Указать" type="number" name="thirdLayer" onChange={onValueChange}/></td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td onClick={addItem} className={styles.addItem}>
                <IconAddPlus />
            </td>
            <td/>
        </tr>
    );
}
