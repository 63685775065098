import React, {useCallback, useState} from 'react';
import API from '../../utils/API';
import NomTableItemInfoRow from '../NomTableItemInfoRow/NomTableItemInfoRow';
import styles from './NomTableItemRow.module.css';
import NomTableItemInfoRowBlank from '../NomTableItemInfoRow/NomTableItemInfoRowBlank';
import NomTableItemInfoRowEditable from '../NomTableItemInfoRow/NomTableItemRowEditable';

export default function NomTableItemRow(props: any) {
    const item = props.itemData;
    const materialId = props.materialId;
    const categoryId = props.categoryId;

    const subCategories = `root-materials/${materialId}/categories/${categoryId}/sub-categories/${item.id}/materials`;

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [subCatOpen, setSubCatOpen] = useState(false);

    const handleClick = () => {
        if (items.length > 0) {
            setSubCatOpen(!subCatOpen);
        } else {
            processCategoriesRequest();
        }
    }

    const handleDelete = useCallback((e: any) => {
        deleteItem(e)
            .then(() => {
                processCategoriesRequest();
            });
    }, []);

    const handleAdd = useCallback((e: any) => {
        addItem(e)
            .then(() => {
                processCategoriesRequest();
            });
    }, [])

    const handleUpdate = useCallback((e: any) => {
        updateItem(e)
            .then(() => {
                processCategoriesRequest();
            });
    }, []);

    const processCategoriesRequest = () => {
        getCategories()
            .then(
                (result) => {
                    setIsLoaded(true);
                    setSubCatOpen(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }

    async function getCategories() {
        const rooMaterialsRequest = await API.get(subCategories);
        return rooMaterialsRequest.data;
    }

    async function deleteItem(e: number) {
        const rooMaterialsRequest = await API.delete(`${subCategories}/${e}`);
        return rooMaterialsRequest.data;
    }

    async function addItem(e: any) {
        const rooMaterialsRequest = await API.post(subCategories, e);
        return rooMaterialsRequest.data;
    }

    async function updateItem(e: any) {
        const rooMaterialsRequest = await API.put(`${subCategories}/${e.id}`, e);
        return rooMaterialsRequest.data;
    }

    const stylesTr = 'nom-row' + `${subCatOpen ? ' open' : ''}`;

    return (
        <>
            <tr className={`${stylesTr} ${styles.nomSubCategory}`} onClick={handleClick}>
                <td/>
                <td colSpan={24}>
                    <div className="sub-item">
                        <div className="sub-item__name"><span className="sec-arrow"/>{item.name}</div>
                        <div className="sub-item__info">{item.info}</div>
                    </div>
                </td>
                <td/>
            </tr>
            {subCatOpen && <tr className="nom-row-blank"/>}
            {subCatOpen && <NomTableItemInfoRowBlank materialId={materialId} categoryId={categoryId} itemId={item.id}
                                                     handleAdd={handleAdd}/>}

            {subCatOpen && items.map((it: any) => <NomTableItemInfoRowEditable key={it.id} material={it}
                                                                               handleDelete={handleDelete}
                                                                               handleUpdate={handleUpdate}/>)}

            {/*{subCatOpen*/}
            {/*    ? items.map((it: any) => <NomTableItemInfoRow key={it.id} itemData={it} handleDelete={handleDelete}/>)*/}
            {/*    : null*/}
            {/*}*/}
            {subCatOpen && <tr className="nom-row-blank"/>}
        </>
    )
}
