import React from 'react';

export default function NomIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.89881 4.36996L2.70955 3.88709C2.67797 3.80653 2.6621 3.72034 2.66285 3.63345C2.66359 3.54656 2.68093 3.46068 2.71388 3.3807C2.74683 3.30072 2.79473 3.22822 2.85487 3.16734C2.915 3.10645 2.98618 3.05838 3.06434 3.02587L9.01297 0.548037C9.17063 0.482462 9.34709 0.484082 9.50359 0.552542C9.66009 0.621003 9.78384 0.750705 9.84765 0.913164L13.4533 10.1128C13.4849 10.1933 13.5007 10.2795 13.5 10.3664C13.4992 10.4533 13.4819 10.5392 13.4489 10.6192C13.416 10.6991 13.3681 10.7716 13.308 10.8325C13.2478 10.8934 13.1766 10.9415 13.0985 10.974L7.14985 13.4518C7.07171 13.4844 6.98811 13.5007 6.90384 13.5C6.81956 13.4992 6.73626 13.4813 6.65868 13.4474C6.58111 13.4134 6.51079 13.364 6.45174 13.302C6.39269 13.24 6.34606 13.1666 6.31453 13.086L6.25807 12.9425V12.969H3.69179C3.52164 12.969 3.35845 12.8993 3.23813 12.7752C3.11781 12.6512 3.05022 12.4829 3.05022 12.3075V12.1289L0.900962 11.2339C0.743231 11.1682 0.617308 11.0405 0.550893 10.879C0.484478 10.7174 0.483011 10.5353 0.546815 10.3727L2.89881 4.36996ZM4.33336 11.646H5.75059L4.33336 8.02983V11.646ZM3.05022 7.51587L1.97687 10.255L3.05022 10.7253V7.51587ZM4.13961 4.00417L7.26405 11.9774L12.0232 9.99503L8.89877 2.02243L4.13961 4.00417ZM6.0502 5.34892C5.89238 5.41462 5.71571 5.41299 5.55906 5.3444C5.40241 5.2758 5.2786 5.14585 5.21488 4.98314C5.15115 4.82042 5.15273 4.63828 5.21927 4.47677C5.28581 4.31526 5.41185 4.18761 5.56967 4.12191C5.72748 4.05613 5.90419 4.05767 6.0609 4.12621C6.21761 4.19474 6.3415 4.32466 6.40531 4.48737C6.46912 4.65008 6.46762 4.83226 6.40114 4.99384C6.33467 5.15541 6.20866 5.28314 6.05084 5.34892H6.0502Z"
            />
        </svg>
    );
}
