const yaAppId = '31d68a213a0940eebf09dac04249910d';
const appUrl = window.location.origin;

export const TOKEN = localStorage.getItem('authData');

export const yaAppUrl = `https://oauth.yandex.ru/authorize?response_type=token&client_id=${yaAppId}&redirect_uri=${appUrl}`;

export const parseToken = (url: string): void => {

    let token = '';
    let tokenType = '';

    url.substr(1, url.length).split('&').map(it => {
        if (it.split('=')[0] === 'access_token') {
            token = it.split('=')[1];
        }

        if (it.split('=')[0] === 'token_type') {
            tokenType = it.split('=')[1];
        }

        return it.split('=');
    });

    if (!TOKEN) {
        setToken(tokenType, token);
    }
}



// export default function getCredentials(credType: string) {
//     const authData = localStorage.getItem('authData' + credType);
//     if (authData != null) {
//         switch (credType) {
//             case 'basic':
//                 const credData = JSON.parse(authData);
//                 return btoa(credData.username + ':' + credData.password);
//             case 'token':
//                 return authData;
//             default:
//                 return null;
//         }
//     }
//     return null;
// }
//

function setToken(type: string, token: string) {
    localStorage.setItem('authData', token);
    // clearQuery();
}

function clearQuery() {
    // window.location.href = window.location.origin + window.location.pathname;
}

//
export const isUserAuthorized =() => {
    return localStorage.getItem('authData') !== null;
}


