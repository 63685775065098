import React from 'react';

export default function MenuBtnCloseSvg() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3.43427 11.4343C3.12185 11.7467 3.12185 12.2532 3.43427 12.5656C3.74669 12.8781 4.25322 12.8781 4.56564 12.5656L7.99995 9.13132L11.4343 12.5656C11.7467 12.8781 12.2532 12.8781 12.5656 12.5656C12.8781 12.2532 12.8781 11.7467 12.5656 11.4343L9.13132 7.99995L12.5656 4.56564C12.8781 4.25322 12.8781 3.74669 12.5656 3.43427C12.2532 3.12185 11.7467 3.12185 11.4343 3.43427L7.99995 6.86858L4.56564 3.43427C4.25322 3.12185 3.74669 3.12185 3.43427 3.43427C3.12185 3.74669 3.12185 4.25322 3.43427 4.56564L6.86858 7.99995L3.43427 11.4343Z"
                  fill="#202227"/>
        </svg>
    );
}
