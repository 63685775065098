import React from 'react';
import './DropdownToggle.css'

export default function DropdownToggle(props: any) {

    const buttonLabel = props.label;

    return (
        <button className="c-btn c-btn__brd-gray dropdown-toggle">{buttonLabel}</button>
    )
}
