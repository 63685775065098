import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.less';
import AppRouting from './routing/AppRouting';

function App() {
  return (
      <AppRouting />
  );
}

export default App;
