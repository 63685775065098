import React from "react";
import './Login.css';
import Logo from '../../components/Logo/Logo';
import {yaAppUrl} from '../../core/Auth/auth-env';

export default function Login() {

    const onLogIn = () => {
        window.location.replace(yaAppUrl)
    }

    return (
        <section className="login-page">
            <div className="login-form-side">
                <div className="app-logo">
                    <Logo />
                </div>
                <div className="login-block">
                    <div className="login-heading">
                        <h1>Войти в систему Batega</h1>
                    </div>
                    <div className="login-description">
                        <p>Используйте корпоративный<br/> аккаунт yandex</p>
                    </div>
                    <div className="login-button">
                        <button onClick={onLogIn}>
                            <span>Войти</span>
                        </button>
                    </div>
                </div>
                <div className="login-help">
                    <p>Трудности со входом?</p>
                    <a href="mailto:help@batega.yandex">help@batega.yandex</a>
                </div>
            </div>
            <div className="gray-side">

            </div>
        </section>
    )
}
