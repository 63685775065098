import React from 'react';
import KanbanCard from '../KanbanCard/KanbanCard';
import './KanbanColumn.css';

export default function KanbanColumn(props: { column: string }) {
    const column = props.column;

    return (
        <div className="kanban-column col-3">
            <div className="column-header d-flex align-items-center justify-content-between">
                <div className="column-title">
                    <h3>{ column }</h3>
                </div>
                <div className="column-actions">
                    <span className="icon icon-sub-menu"></span>
                </div>
            </div>
            <div className="column-body">
                <KanbanCard />
            </div>
        </div>
    )
}
