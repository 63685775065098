import React from 'react';
import './UserImg.css';

export default function UserImg() {
    return (
    <div className="user-avatar">
        <img src="https://www.fillmurray.com/300/300" alt="" className="img-fluid"/>
    </div>
    );
}
