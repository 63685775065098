import React from 'react';

export default function CreateItemBtn(props: any) {

    const label = props.label;
    const type = props.type;
    const styles = type === 'create' ? 'c-btn btn-add bg-blue' : 'c-btn btn-update bg-blue';

    return (
        <button className={styles}>{label}</button>
    )
}
