import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';
import ModalTitle from '../../Common/ModalTitle/ModalTitle';
import SectionCreateModal from '../../SectionCreateModal/SectionCreateModal';
import styles from './../../Common/Common.module.css';
import API from '../../../utils/API';

export default function CreateSubSectionModal(props: any) {

    const rootMaterialsCategories = `root-materials/${props.materialId}/categories`;
    const [isModalVisible, setIsModalVisible] = useState(props.visible);
    const [inputValue, setInputValue] = useState('');

    async function saveData() {
        const rooMaterialsRequest = await API.post(rootMaterialsCategories, {
            name: inputValue
        });

        return rooMaterialsRequest.data;
    }

    const handleOk = () => {
        if (inputValue.length > 0) {
            saveData()
                .then(
                    () => {
                        props.handleChange(false);
                        setIsModalVisible(false);
                    });
        }
    };

    const handleCancel = (e: any) => {
        props.handleChange(false);
        setIsModalVisible(false);
    };

    const handleOnChange = (e: any) => {
        setInputValue(e);
    }

    return (
        <Modal
            title={<ModalTitle label={'Новый раздел'}/>}
            className={styles.addSectionModal}
            destroyOnClose={true}
            visible={isModalVisible}
            width={420}
            footer={
                <>
                    <button className="c-btn btn-update bg-blue" onClick={handleOk}>Добавить</button>
                    <button className={styles.modalCancelBtn} onClick={handleCancel}>Отменить</button>
                </>
            }
            onOk={handleOk}
            onCancel={handleCancel}>
            <SectionCreateModal onChange={handleOnChange}/>
        </Modal>
    );
}
