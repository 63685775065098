import styles from './NomTableItemInfoRow.module.css';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import IconTrashBin from '../../svg/IconTrashBin';
import {throttle} from 'lodash';

interface MaterialRequestEntity {
    vendorCode: number;
    title: string;
    count: number;
    weight: number;
    unit: string;
    price: number;
    markup: number;
    logistic: number;
    vat: number;
    firstLayer: number;
    secondLayer: number;
    thirdLayer: number;
}

export default function NomTableItemInfoRowEditable(props: {
    material: any,
    handleUpdate: (material: any) => void;
    handleDelete: (material: any) => void;
}) {
    const material = props.material;

    const onValueChange = (e: any) => {
        let {name, value} = e.target;

        const updatedMaterial = {
            ...material,
            [name]: transformValue(value, name)
        }

        props.handleUpdate(updatedMaterial);
    }

    const throttled = useCallback(throttle(e => {
        if (e.keyCode == 13) {
            onValueChange(e);
        }
    }, 500), [onValueChange]);

    const deleteItem = () => {
        props.handleDelete(material.id);
    }

    const transformValue = (val: number | string, name: string) => {

        if (name === 'unit') {
            return val;
        }

        if (name === 'title') {
            return val;
        }

        return Number(val);
    }

    const roundNum = (e: number) => {
        if (e) {
            return e.toFixed(2);
        }
    }

    return (
        <tr className={`nom-row ${styles.nomItem} ${styles.nomItemBlank} ${styles.rowEditable}`}>
            <td/>
            <td><input placeholder="Указать" type="text" name="vendorCode" defaultValue={material.vendorCode}
                       onKeyDown={throttled}/></td>

            <td><input placeholder="Указать" type="text" name="title" defaultValue={material.title}
                       onKeyDown={throttled}/>
            </td>

            <td><input placeholder="Указать" type="number" name="price" defaultValue={material.price}
                       onKeyDown={throttled}/></td>
            <td>{material.currencyPrice}</td>

            <td><input placeholder="Указать" type="number" name="vat" defaultValue={material.vat}
                       onKeyDown={throttled}/>
            </td>
            <td>{material.vatPrice}</td>

            <td><input placeholder="Указать" type="number" name="logistics" defaultValue={material.logistics}
                       onKeyDown={throttled}/></td>
            <td>{material.logisticsPrice}</td>
            <td>{material.costPrice}</td>

            <td><input placeholder="Указать" type="number" name="markup" defaultValue={material.markup}
                       onKeyDown={throttled}/></td>
            <td>{material.markupPrice}</td>

            <td><input placeholder="Указать" type="number" name="count" defaultValue={material.count}
                       onKeyDown={throttled}/></td>

            <td><input placeholder="Указать" type="number" name="weight" defaultValue={material.weight}
                       onKeyDown={throttled}/></td>

            <td><input placeholder="Указать" type="text" name="unit" defaultValue={material.unit}
                       onKeyDown={throttled}/>
            </td>

            <td><input placeholder="Указать" type="number" name="firstLayer"
                       defaultValue={material.firstLayerConsumption}
                       onKeyDown={throttled}/></td>
            <td><input placeholder="Указать" type="number" name="secondLayer"
                       defaultValue={material.secondLayerConsumption}
                       onKeyDown={throttled}/></td>
            <td><input placeholder="Указать" type="number" name="thirdLayer"
                       defaultValue={material.thirdLayerConsumption}
                       onKeyDown={throttled}/></td>

            <td>{roundNum(material.firstLayerPackageConsumption) || '-'}</td>
            <td>{roundNum(material.secondLayerPackageConsumption) || '-'}</td>
            <td>{roundNum(material.thirdLayerPackageConsumption) || '-'}</td>

            <td>{material.priceForOne}</td>
            <td>{material.firstLayerPrice || '-'}</td>
            <td>{material.secondLayerPrice || '-'}</td>

            <td className={styles.addItem}>
                <div onClick={deleteItem}>
                    <IconTrashBin/>
                </div>
            </td>
            <td/>
        </tr>
    );
}
