import React from 'react';

export default function LogoSmall() {
    return (
        <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3.23077 7.39139V0H0V13.1579C0 16.9367 3.13401 20 7 20C10.866 20 14 16.9367 14 13.1579C14 9.3791 10.866 6.31579 7 6.31579C5.61223 6.31579 4.31879 6.71052 3.23077 7.39139ZM3.23077 13.1579C3.23077 15.1926 4.91831 16.8421 7 16.8421C9.08169 16.8421 10.7692 15.1926 10.7692 13.1579C10.7692 11.1232 9.08169 9.47368 7 9.47368C4.91831 9.47368 3.23077 11.1232 3.23077 13.1579Z"
                  fill="#202227"/>
        </svg>
    )
}
