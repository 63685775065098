import React from 'react';

export default function VendorsIcon() {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.66667 4.66667V7.33333H10.6667V4.66667H6.66667ZM5.33333 4.66667H1.33333V7.33333H5.33333V4.66667ZM6.66667 10.6667H10.6667V8.66667H6.66667V10.6667ZM5.33333 10.6667V8.66667H1.33333V10.6667H5.33333ZM6.66667 1.33333V3.33333H10.6667V1.33333H6.66667ZM5.33333 1.33333H1.33333V3.33333H5.33333V1.33333ZM0.666667 0H11.3333C11.5101 0 11.6797 0.0702379 11.8047 0.195262C11.9298 0.320286 12 0.489856 12 0.666667V11.3333C12 11.5101 11.9298 11.6797 11.8047 11.8047C11.6797 11.9298 11.5101 12 11.3333 12H0.666667C0.489856 12 0.320286 11.9298 0.195262 11.8047C0.0702379 11.6797 0 11.5101 0 11.3333V0.666667C0 0.489856 0.0702379 0.320286 0.195262 0.195262C0.320286 0.0702379 0.489856 0 0.666667 0Z"
            />
        </svg>
    )
}
