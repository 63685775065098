import React from 'react';
import DropdownToggle from '../../components/Common/DropdownToggle/DropdownToggle';

export default function Vendors(props: any) {

    return (
        <section className="vendors-section">
            <div className="section-actions">
                <div className="container-fluid">
                    <div className="row justify-content-between align-content-center">
                        <div className="col">
                            <div className="section-options">
                                <DropdownToggle label={'Клиент'} data={['one', 'two', 'three']}/>
                                <DropdownToggle label={'Департамен'} data={['one', 'two', 'three']}/>
                                <DropdownToggle label={'Менеджер'} data={['one', 'two', 'three']}/>
                                <DropdownToggle label={'Технолог'} data={['one', 'two', 'three']}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h1>Артикулы</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">

                    </div>
                </div>
            </div>
        </section>
    )
}
