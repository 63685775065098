import React from 'react';
import styles from './../Common.module.css';

export default function ModalTitle(props: {label: string}) {
    return (
        <h1 className={styles.modalTitle}>
            {props.label}
        </h1>
    )
}
