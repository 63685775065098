import React from 'react';

export default function RequestsIcon() {
    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.8 3.33333H12.2V1.33333H1.8V3.33333ZM8.3 10.6667V4.66667H5.7V10.6667H8.3ZM9.6 10.6667H12.2V4.66667H9.6V10.6667ZM4.4 10.6667V4.66667H1.8V10.6667H4.4ZM1.15 0H12.85C13.0224 0 13.1877 0.0702379 13.3096 0.195262C13.4315 0.320286 13.5 0.489856 13.5 0.666667V11.3333C13.5 11.5101 13.4315 11.6797 13.3096 11.8047C13.1877 11.9298 13.0224 12 12.85 12H1.15C0.977609 12 0.812279 11.9298 0.690381 11.8047C0.568482 11.6797 0.5 11.5101 0.5 11.3333V0.666667C0.5 0.489856 0.568482 0.320286 0.690381 0.195262C0.812279 0.0702379 0.977609 0 1.15 0Z"
            />
        </svg>

    );
}
