import React from 'react';
import styles from './AddSectionTableBtn.module.css';
import IconAddPlus from '../../svg/IconAddPlus';

export default function AddSectionTableBtn(
    props: {
        label: string,
        state: any,
        callback: any
    }
) {

    const btnLabel = props.label;

    const handleClick = () => {

        props.callback(!props.state);
    }

    return (
        <tr className={styles.addSection} onClick={handleClick}>
            <td />
            <td colSpan={24}>
                <div className={styles.addSectionButton}>
                    <IconAddPlus />
                    {btnLabel}
                </div>
            </td>
            <td />
        </tr>
    )
}
