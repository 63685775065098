import React, {useCallback, useState} from "react";
import './Requests.css';
import Kanban from '../../components/Kanban/Kanban';
import IconBtn from '../../components/Common/IconBtn/IconBtn';
import DropdownToggle from '../../components/Common/DropdownToggle/DropdownToggle';
import CreateItemBtn from '../../components/Common/CreateItemBtn/CreateItemBtn';
import Table from '../../components/Table/Table';
import TextBtn from '../../components/Common/TextBtn/TextBtn';

export default function Requests(props: any) {

    const [viewType, setViewType] = useState(true);

    const clickCallback = useCallback(
        e => {
            setViewType(e);
        }, []
    );

    return (
        <section className="request-page">
            <div className="section-actions">
                <div className="container-fluid">
                    <div className="row justify-content-between align-content-center">
                        <div className="col">
                            <div className="section-options">
                                <IconBtn clickCallback={clickCallback} state={viewType}>
                                    <span className="icon icon-table-view" />
                                </IconBtn>
                                {!viewType && <TextBtn label={'Готовые'}/>}
                                <DropdownToggle label={'Тип работ'} data={['one', 'two', 'three']}/>
                                <DropdownToggle label={'Ответственный'} data={['one', 'two', 'three']}/>
                                {!viewType && <DropdownToggle label={'Статус заявки'} data={['one', 'two', 'three']}/>}
                            </div>
                        </div>
                        <div className="col text-right">
                            <CreateItemBtn label={'Новая заявка'} type={'create'} />
                        </div>
                    </div>
                </div>
            </div>
            { viewType
                ? <Kanban />
                : <Table />
            }
        </section>
    );
}
