import React from 'react';

export default function IconBtn(props: any) {

    const handleCLick = () => {
        props.clickCallback(!props.state);
    }

    return (
        <button className="c-btn__textless c-btn__brd-gray view" onClick={handleCLick}>
            {props.children}
        </button>
    )
}
