import React from 'react';
import styles from './Table.module.css';
import TableRow from '../TableRow/TableRow';

export default function Table() {

    const requestList = [
        {
            id: 0,
            status: 'new',
            type: 'interior',
            type_label: 'интерьер'
        },
        {
            id: 1,
            status: 'new',
            type: 'interior',
            type_label: 'интерьер'
        },
        {
            id: 2,
            status: 'pending',
            type: 'facade',
            type_label: 'фасад'
        },
        {
            id: 3,
            status: 'pending',
            type: 'facade',
            type_label: 'фасад'
        },
        {
            id: 4,
            status: 'in-progress',
            type: 'floor',
            type_label: 'пол'
        },
        {
            id: 5,
            status: 'in-progress',
            type: 'floor',
            type_label: 'пол'
        }
    ];

    return (
        <div className="table-view">
            <table className="table">
                <thead className={styles.tableThead}>
                <tr>
                    <th />
                    <th className={ styles.sortable }>Номер заявки</th>
                    <th className={ styles.sortable }>Создано</th>
                    <th>Тип</th>
                    <th>Менеджер</th>
                    <th>Технолог</th>
                    <th className={ styles.sortable }>Сроки</th>
                    <th className={ styles.sortable }>Образцы</th>
                    <th>Статус</th>
                    <th />
                </tr>
                </thead>

                <tbody>
                <tr className={styles.blankTr}>
                    <td colSpan={10} />
                </tr>
                {requestList.map(request => <TableRow data={request} key={request.id}/>)}
                </tbody>
            </table>
        </div>
    );
}
