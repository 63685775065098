import React from 'react';
import {isUserAuthorized, parseToken} from './auth-env';
import Login from '../../pages/Login/Login';
import Layout from '../../components/Layout/Layout';

export default function AuthContainer({children}: any) {
    parseToken(window.location.hash);
    const isAuthorized = isUserAuthorized();

    return isAuthorized ? <Layout> {children} </Layout> : <Login />;
}
