import React from 'react';
import './Sidebar.css';
import {NavLink} from 'react-router-dom';
import LogoSmall from '../../LogoSmall/LogoSmall';
import {routes} from '../../../routing/routes';

export default function Sidebar() {


    return (
        <div className="sidebar sidebar_thin">
            <div className="sidebar__logo">
                <div className="sidebar__logo_container">
                    <LogoSmall/>
                </div>
            </div>
            <div className="sidebar__navigation">
                <ul className="sidebar__nav-block">
                    {routes.map((route, index) => (
                        <NavLink to={route.path} key={index} activeClassName="active">
                            <li className="sidebar__nav-item">
                                <div className="icon">
                                    <route.icon/>
                                </div>
                                <div className="sidebar__nav-title">{route.label}</div>
                            </li>
                        </NavLink>
                    ))}
                </ul>
            </div>
        </div>
    )
}
