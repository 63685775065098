import React from 'react';
import './TableRow.css';
import UserImg from '../UserImg/UserImg';

export default function TableRow(props: any) {

    const request = props.data;

    return (
        <tr className="request-row">
            <td />
            <td className="request-id">
                # AD2547 <span className="icon icon-arrow" />
            </td>
            <td className="request-created-date">Сегодня, 12:47</td>
            <td className={'request-category ' + request.type}>
                <div>{request.type_label}</div>
            </td>
            <td className="request-manager">
                <div>
                    <UserImg />
                    ФИО
                </div>
            </td>
            <td className="request-technologist">кружок</td>
            <td className="request-term">4 д</td>
            <td className="request-examples">Указать</td>
            <td className="request-status">
                <div className={'status-'+request.status} />
                Новая</td>
            <td />
        </tr>
    );
}
