import React from 'react';
import './UserInfoHeader.css';

export default function UserInfoHeader() {
    return (
        <div className="user-info-header">
            <div className="user-info__name">Видишев Д. А.</div>
            <div className="user-info__position">Технолог</div>
        </div>
    );
}
