import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import AuthContainer from '../core/Auth/AuthContainer';
import {routes} from './routes';


export default function AppRouting() {

    return (
        <Router>
            <Switch>
                <Route exact path="/" render={() => (<Redirect to="/requests" />)} />
                <AuthContainer>
                {routes.map((route, index) => (
                    <Route path={route.path} key={index}>
                        <route.component />
                    </Route>
                ))}
                </AuthContainer>
            </Switch>
        </Router>
    );
}
