import React, {useCallback, useEffect, useState} from 'react';
import NomTableSubRow from '../NomTableSubRow/NomTableSubRow';
import API from '../../utils/API';
import styles from './NomTableRootRow.module.css';
import AddSectionTableBtn from '../AddSectionTableBtn/AddSectionTableBtn';
import CreateSubSectionModal from '../NomComponents/CreateSubSection/CreateSubSection';

export default function NomTableRootRow(props: any) {
    const material = props.material;
    const rootMaterialsCategories = `root-materials/${material.id}/categories`;

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [modalVisible, setIsModalVisible] = useState(false);

    async function getCategories() {
        const rooMaterialsRequest = await API.get(rootMaterialsCategories);
        return rooMaterialsRequest.data;
    }

    useEffect(() => {

        getCategories()
            .then(
                (result) => {
                    setIsLoaded(true);
                    setCategories(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );

    }, []);

    const tableBtnCallback = () => {
        setIsModalVisible(!modalVisible);
    }

    const handleModalState = useCallback(
        e => {
            setIsModalVisible(e);
        }, []
    );

    return (
        <>
            <tr className={styles.nomRootRow}>
                <td/>
                <td colSpan={24}>{material.name}</td>
                <td/>
            </tr>
            <AddSectionTableBtn label={'Добавить подраздел'} state={modalVisible} callback={tableBtnCallback}/>
            {categories.map((it: { id: number, name: string }) => <NomTableSubRow key={it.id} category={it}
                                                                                  materialId={material.id}/>)}

            {modalVisible &&
            <CreateSubSectionModal visible={modalVisible} handleChange={handleModalState} materialId={material.id}/>}

        </>
    )
}
