import React from 'react';
import KanbanColumn from '../KanbanColumn/KanbanColumn';
import styles from './Kanban.module.css';

export default function Kanban() {

    const kanbanColumns = ['Новые', 'Ожидание', 'В работе', 'Готово'];

    return (
        <div className={styles.KanbanView}>
            <div className="container-fluid">
                <div className="row">
                    { kanbanColumns.map(it => <KanbanColumn key={it} column={it} />) }
                </div>
            </div>
        </div>
    );
}
