import React from 'react';
import MenuBtnSvg from '../Common/MenuBtn/MenuBtnSvg';
import Logo from '../Logo/Logo';
import UserImg from '../UserImg/UserImg';

import './AppNav.css';
import UserInfoHeader from '../UserInfoHeader/UserInfoHeader';
import MenuBtnCloseSvg from '../Common/MenuBtn/MenuBtnCloseSvg';

export default function AppNav(props: any) {

    const pageTitle = props.pageTitle;

    const isMenuOpen = props.menuState ? 'menu-open' : '';

    const onMenuToggleChanged = () => {
        props.changeMenuState(!props.menuState);
    }

    return (
        <nav className="app-navigation">
            <div className="container-fluid">
                <div className="page-header row justify-content-between align-content-center">
                    <div className="col text-left">
                        <div className="page-title">
                            <button className="menu-btn" onClick={onMenuToggleChanged}>
                                { isMenuOpen ? <MenuBtnCloseSvg /> : <MenuBtnSvg /> }
                            </button>
                            <h1>{pageTitle}</h1>
                        </div>
                    </div>
                    <div className="col text-center d-flex align-items-center justify-content-center">
                        <Logo />
                    </div>
                    <div className="col text-right">
                        <div className="user-info">
                            <UserInfoHeader />
                            <UserImg />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}
