import React, {useCallback, useState} from 'react';
import './Layout.css';
import AppNav from '../AppNav/AppNav';
import Sidebar from '../Common/Sidebar/Sidebar';

export default function Layout(props: {
    children: any
}) {

    const [menuOpen, setMenuOpen] = useState(false);

    const styles = {
        layout: menuOpen ? 'layout menu-open' : 'layout'
    }

    const catchMenuToggleEvent = useCallback(
        e => {
            setMenuOpen(e);
        }, []
    );


    return (
      <div className={styles.layout}>
          {menuOpen && <Sidebar/>}
          <div className="content">
              <AppNav pageTitle={'Список '} menuState={menuOpen} changeMenuState={catchMenuToggleEvent}/>
              {props.children}
          </div>
      </div>
    );
}
