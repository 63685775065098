import React from 'react';
import './Nomenclature.css';
import DropdownToggle from '../../components/Common/DropdownToggle/DropdownToggle';
import CreateItemBtn from '../../components/Common/CreateItemBtn/CreateItemBtn';
import NomTable from '../../components/NomTable/NomTable';

export default function Nomenclature() {

    return (
        <section className="nomenclature-section">
            <div className="section-actions">
                <div className="container-fluid">
                    <div className="row justify-content-between align-content-center">
                        <div className="col">
                            <div className="section-options">
                                <DropdownToggle label={'Раздел'} data={['one', 'two', 'three']}/>
                                <DropdownToggle label={'Производитель'} data={['one', 'two', 'three']}/>
                            </div>
                        </div>
                        <div className="col text-right">
                            <CreateItemBtn label={'Редактировать'} type={'update'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="table-container">
                <NomTable />
            </div>
        </section>
    );
}
