import React, {useRef, useState} from 'react';
import styles from './SectionCreateModal.module.css';
import CreateItemBtn from '../Common/CreateItemBtn/CreateItemBtn';
import API from '../../utils/API';
import {Modal} from 'antd';

export default function SectionCreateModal(props: any) {

    const onValueChange = (e: any) => {
        props.onChange(e.target.value);
    }

    return (
        <>
            <div className={styles.formControl}>
                <label>Название</label>
                <input type="text" placeholder={'Введите'} onChange={onValueChange} />
            </div>
        </>
    )
}
