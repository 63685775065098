import React, {useCallback, useEffect, useState} from 'react';
import API from '../../utils/API';
import NomTableRootRow from '../NomTableRow/NomTableRootRow';
import styles from './NomTable.module.css';
import AddSectionTableBtn from '../AddSectionTableBtn/AddSectionTableBtn';
import CreateSectionModal from '../NomComponents/CreateSectionModal/CreateSectionModal';

export default function NomTable() {

    const rootMaterialsApi = 'root-materials';

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [materials, setMaterials] = useState([]);
    const [modalVisible, setIsModalVisible] = useState(false);

    async function getMaterials() {
        const rooMaterialsRequest = await API.get(rootMaterialsApi, {
            params: {
                skip: 0,
                take: 50,
                active: false
            }
        });
        return rooMaterialsRequest.data;
    }

    useEffect(() => {

        getMaterials()
            .then(
                (result) => {
                    setIsLoaded(true);
                    setMaterials(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );

    }, []);

    const tableBtnCallback = () => {
        setIsModalVisible(!modalVisible);
    }

    const handleModalState = useCallback(
        e => {
            setIsModalVisible(e);
        }, []
    );

    return (
        <>
            <table className={styles.table}>
                <thead className={styles.tableThead}>
                <tr>
                    <th rowSpan={2}/>
                    <th rowSpan={2} style={{width: '8rem'}}>Артикул</th>
                    <th rowSpan={2} style={{width: '17rem'}}>Наименование</th>

                    <th colSpan={2} style={{width: '12rem'}}>Закупочная стоимость</th>
                    <th colSpan={2} style={{width: '10rem'}}>НДС</th>
                    <th colSpan={2} style={{width: '14rem'}}>Логистика</th>
                    <th colSpan={1} style={{width: '7rem'}}>Себес-сть</th>
                    <th colSpan={2} style={{width: '16rem'}}>Наценка</th>

                    <th rowSpan={2} style={{width: '7rem'}}>Склад, шт</th>
                    <th colSpan={2} style={{width: '11rem'}}>Фасовка</th>
                    <th colSpan={3} style={{width: '18rem'}}>Расход кг/м.кв</th>
                    <th colSpan={3} style={{width: '18rem'}}>Расход фасовки</th>
                    <th rowSpan={2} style={{width: '6rem'}}>Цена</th>
                    <th colSpan={2} style={{width: '13rem'}}>Цена 1 м2</th>
                    <th rowSpan={2} style={{width: '3rem'}}/>
                    <th rowSpan={2}/>
                </tr>
                <tr>
                    <th>EUR</th>
                    <th>РУБ</th>

                    <th>НДС</th>
                    <th>Сумма</th>

                    <th>Логистика</th>
                    <th>Сумма</th>

                    <th>ЗС + Н + Л</th>

                    <th>Наценка, %</th>
                    <th>Итого нац.</th>

                    <th style={{width: '4rem'}}>Вес</th>
                    <th style={{width: '4rem'}}>Ед. изм</th>

                    <th style={{width: '4rem'}}>1 слой</th>
                    <th style={{width: '4rem'}}>2 слоя</th>
                    <th style={{width: '4rem'}}>3 слоя</th>

                    <th style={{width: '4rem'}}>1 слой</th>
                    <th style={{width: '4rem'}}>2 слоя</th>
                    <th style={{width: '4rem'}}>3 слоя</th>

                    <th style={{width: '2rem'}}>в 1 слой</th>
                    <th style={{width: '2rem'}}>в 2 слоя</th>
                </tr>
                </thead>

                <tbody>
                <tr className={styles.nomBlankTr}>
                    <td colSpan={26}/>
                </tr>
                <AddSectionTableBtn label={'Добавить раздел'} state={modalVisible} callback={tableBtnCallback}/>
                {materials.map((it: { id: number, name: string }) => <NomTableRootRow material={it} key={it.id}/>)}
                </tbody>
            </table>

            {modalVisible && <CreateSectionModal visible={modalVisible} handleChange={handleModalState}/>}

        </>
    )
}
