import Requests from '../pages/Requests/Requests';
import Nomenclature from '../pages/Nomenclature/Nomenclature';
import Users from '../pages/Users/Users';
import Vendors from '../pages/Vendors/Vendors';
import RequestsIcon from '../svg/RequestsIcon';
import NomIcon from '../svg/NomIcon';
import VendorsIcon from '../svg/VendorsIcon';
import UsersIcon from '../svg/UsersIcon';

export const routes = [
    {
        path: '/requests',
        component: Requests,
        exact: true,
        label: 'Список заявок',
        icon: RequestsIcon
    },
    {
        path: '/nomenclature',
        component: Nomenclature,
        exact: true,
        label: 'Номеклатура',
        icon: NomIcon
    },
    {
        path: '/vendors',
        component: Vendors,
        exact: true,
        label: 'Артикулы',
        icon: VendorsIcon
    },
    {
        path: '/users',
        component: Users,
        exact: true,
        label: 'Пользователи',
        icon: UsersIcon
    }
];
