import React from 'react';
import DropdownToggle from '../../components/Common/DropdownToggle/DropdownToggle';
import CreateItemBtn from '../../components/Common/CreateItemBtn/CreateItemBtn';
import TextBtn from '../../components/Common/TextBtn/TextBtn';

export default function Users() {
    return (
        <section className="users-section">
            <div className="section-actions">
                <div className="container-fluid">
                    <div className="row justify-content-between align-content-center">
                        <div className="col">
                            <div className="section-options">
                                <TextBtn label={'Архивные'}/>
                                <DropdownToggle label={'Департамен'} data={['one', 'two', 'three']}/>
                                <DropdownToggle label={'Роль'} data={['one', 'two', 'three']}/>
                                <DropdownToggle label={'Статус'} data={['one', 'two', 'three']}/>
                            </div>
                        </div>
                        <div className="col text-right">
                            <CreateItemBtn label={'Добавить'} type={'create'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h1>Пользователи</h1>
                    </div>
                </div>
            </div>
        </section>
    )
}
