import React, {useState} from 'react';
import styles from './../Styles.module.css';
import {Modal} from 'antd';
import ModalTitle from '../../Common/ModalTitle/ModalTitle';
import SectionCreateModal from '../../SectionCreateModal/SectionCreateModal';
import API from '../../../utils/API';

export default function CreatePositionModal(props: any) {

    const subCategoriesApi = `root-materials/${props.materialId}/categories/${props.categoryId}/sub-categories`;
    const [isModalVisible, setIsModalVisible] = useState(props.visible);
    const [itemValue, setItemValue] = useState({
        name: '',
        info: ''
    });

    async function saveData() {
        const rooMaterialsRequest = await API.post(subCategoriesApi, itemValue);
        return rooMaterialsRequest.data;
    }


    const onValueChange = (e: any) => {
        const {name, value} = e.target;

        setItemValue(prevState => ({
            ...prevState,
            [name]: value
        }));

    }

    const handleOk = () => {
        saveData()
            .then(
                () => {
                    props.handleChange(false);
                    setIsModalVisible(false);
                });
    };

    const handleCancel = (e: any) => {
        props.handleChange(false);
        setIsModalVisible(false);
    };

    return (
        <>
            <Modal
                title={<ModalTitle label={'Новая позиция'}/>}
                className={styles.addSectionModal}
                destroyOnClose={true}
                visible={isModalVisible}
                width={420}
                footer={
                    <>
                        <button className="c-btn btn-update bg-blue" onClick={handleOk}>Добавить</button>
                        <button className={styles.modalCancelBtn} onClick={handleCancel}>Отменить</button>
                    </>
                }
                onOk={handleOk}
                onCancel={handleCancel}>

                <div className={styles.formControl}>
                    <label>Название</label>
                    <input type="text" name="name" placeholder={'Введите'} onChange={onValueChange}/>
                </div>
                <div className={styles.formControl}>
                    <label>Описание</label>
                    <input type="text" name="info" placeholder={'Введите'} onChange={onValueChange}/>
                </div>

            </Modal>

        </>
    )
}
