import React from 'react';

export default function IconAddPlus() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.35 6.35V3.75H7.65V6.35H10.25V7.65H7.65V10.25H6.35V7.65H3.75V6.35H6.35ZM7 13.5C3.41005 13.5 0.5 10.5899 0.5 7C0.5 3.41005 3.41005 0.5 7 0.5C10.59 0.5 13.5 3.41005 13.5 7C13.5 10.5899 10.59 13.5 7 13.5ZM7 12.2C8.37913 12.2 9.70177 11.6521 10.677 10.677C11.6521 9.70177 12.2 8.37913 12.2 7C12.2 5.62087 11.6521 4.29823 10.677 3.32304C9.70177 2.34786 8.37913 1.8 7 1.8C5.62087 1.8 4.29823 2.34786 3.32305 3.32304C2.34786 4.29823 1.8 5.62087 1.8 7C1.8 8.37913 2.34786 9.70177 3.32305 10.677C4.29823 11.6521 5.62087 12.2 7 12.2Z"
                fill="#0192F9"/>
        </svg>
    );
}
