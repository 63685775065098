import React, {useCallback, useEffect, useState} from 'react';
import NomTableItemRow from '../NomTableItemRow/NomTableItemRow';
import API from '../../utils/API';
import styles from './NomTableSubRow.module.css'
import SectionPopover from '../NomComponents/SectionPopover/SectionPopover';

export default function NomTableSubRow(props: any) {
    const category = props.category;
    const materialId = props.materialId;
    const categoryApi = `root-materials/${materialId}/categories`;
    const subCategoriesApi = `root-materials/${materialId}/categories/${category.id}/sub-categories`;

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [subCatOpen, setSubCatOpen] = useState(false);

    const toggleSection = () => {
        if (items.length > 0) {
            setSubCatOpen(!subCatOpen);
        } else {
            processCategoriesRequest();
        }
    }

    const processCategoriesRequest = () => {
        getCategories()
            .then(
                (result) => {
                    setIsLoaded(true);
                    setSubCatOpen(!subCatOpen);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }

    async function getCategories() {
        const rooMaterialsRequest = await API.get(subCategoriesApi);
        return rooMaterialsRequest.data;
    }

    const subCat = 'nom-row' + `${subCatOpen ? ' open' : ''}`;

    const handleDeleteEvent = useCallback(
        e => {
            deleteCategory(e)
                .then(() => getCategories()).then(it => setItems(it));
        }, []
    );

    async function deleteCategory(id: number) {
        const rooMaterialsRequest = await API.delete(`${categoryApi}/${id}`, );

        return rooMaterialsRequest.data;
    }

    return (
        <>
            <tr className={`${subCat} ${styles.nomCategory}`}>
                <td/>
                <td colSpan={2} onClick={toggleSection}><span className="sec-arrow"/>{category.name}</td>
                <td colSpan={21}/>
                <td className={styles.popoverBtn}>
                    <SectionPopover materialId={materialId} categoryId={category.id} handleDelete={handleDeleteEvent}/>
                </td>
                <td/>
            </tr>
            {subCatOpen
                ? items.map((it: { id: number, name: string }) =>
                    <NomTableItemRow key={it.id} itemData={it}
                                     materialId={materialId}
                                     categoryId={category.id}/>)
                : null
            }
        </>
    )
}
