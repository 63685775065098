import React from 'react';

export default function UsersIcon() {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99968 9.85048C8.33989 9.85048 10.3868 10.8848 11.5 12.428L10.3229 13C9.41669 11.8967 7.81906 11.1639 5.99968 11.1639C4.1803 11.1639 2.58267 11.8967 1.67649 13L0.5 12.4274C1.61323 10.8841 3.65947 9.85048 5.99968 9.85048ZM5.99968 0C6.84712 0 7.65984 0.345938 8.25907 0.961712C8.8583 1.57749 9.19494 2.41266 9.19494 3.28349V5.25359C9.19489 6.09972 8.87698 6.91317 8.30751 7.5243C7.73804 8.13544 6.96097 8.49707 6.13835 8.5338L5.99968 8.53708C5.15224 8.53708 4.33952 8.19114 3.74029 7.57537C3.14106 6.95959 2.80442 6.12442 2.80442 5.25359V3.28349C2.80447 2.43736 3.12238 1.62391 3.69185 1.01278C4.26132 0.401641 5.03839 0.0400057 5.86101 0.00328357L5.99968 0ZM5.99968 1.3134C5.51067 1.31337 5.04013 1.50537 4.68434 1.85011C4.32856 2.19484 4.11441 2.66627 4.08572 3.16791L4.08252 3.28349V5.25359C4.08204 5.76641 4.27617 6.25921 4.62366 6.62728C4.97114 6.99535 5.4446 7.20968 5.94342 7.22473C6.44224 7.23978 6.92712 7.05436 7.29502 6.70787C7.66292 6.36138 7.88484 5.88113 7.91364 5.36917L7.91684 5.25359V3.28349C7.91684 2.76099 7.71485 2.25989 7.35531 1.89042C6.99578 1.52096 6.50814 1.3134 5.99968 1.3134Z"
            />
        </svg>
    )
}
