import React from 'react';

export default function TextBtn(props: {
    label: string
}) {
    const buttonLabel = props.label;

    return (
        <button className="c-btn c-btn__brd-gray c-btn_text-gray">{buttonLabel}</button>
    )
}
