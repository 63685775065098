import React from 'react';
import './KanbanCard.css';
import UserImg from '../UserImg/UserImg';

export default function KanbanCard() {

    return (
        <div className="kanban-card">
            <div className="kanban-card-content">
                <div className="kanban-card__top">
                    <div className="task-id"># 1746</div>
                    <div className="task-created-date">06 ноя</div>
                </div>
                <div className="kanban-card__bottom">
                    <div className="request-category">Дешевые</div>
                    <div className="task-updated-at icon-block">
                        <span className="icon icon-clock"></span>3 ч</div>
                    <div className="check-list icon-block">
                        <span className="icon icon-check"></span>0/3</div>
                    <div className="contractor">
                        <UserImg />
                    </div>
                </div>
            </div>
        </div>
    );
}
